import React from "react";
import {graphql} from "gatsby";
import Img from "gatsby-image";
import Layout from "../templates/layout";
import Contact from "../components/contactButton";

//SBS = Side by side (two quotes next to each other, if size allows)
const SBS = (props) => (
    <div className="block lg:flex justify-between mx-auto max-w-xs mb-5 sm:max-w-sm md:max-w-md lg:max-w-4xl xl:max-w-6xl">
        <div className="">
            <div className="text-gray-700 text-lg">
                {props.lQ}   
            </div>
            <div>
                <p className="italic text-green-700 mt-2">-{props.lP}</p>
                <p>{props.lC}</p>    
            </div>                 
        </div>
                
        <div className="hidden lg:block border-l-2 border-gray-400 h-64 mt-20 mx-10"></div>
                
        <div className=" lg:text-right mt-5 lg:mt-0">
            <div className="text-gray-700 text-lg">
               {props.rQ}
            </div>
            <div className="text-left">
                <p className="italic text-green-700 mt-2">-{props.rP}</p>
                <p>{props.rC}</p>
            </div>
        </div>
    </div>
)

const Single = (props) => (
    <div className="block max-w-xs mx-auto mb-5 sm:max-w-sm md:max-w-md lg:max-w-2xl lg:mt-10">
        <hr className="hidden lg:block border border-gray-400 mb-5" />
        <div className="max-w-xl mx-auto">
            <div className="text-gray-700 text-lg">
                {props.Q}
            </div>
            <div className="mt-5">
                <p className="italic text-green-700">-{props.P}</p>
                <p>{props.C}</p>
            </div>
        </div>
    </div>
)

export default (props) => {
    const data = props.data.allContentfulTestimonialPage.nodes[0]
    const banner = data.banner;
    const testimonials = data.testimonialList;
    let sbsT = null;
    if(testimonials.length >= 4){
        sbsT = <SBS lQ={testimonials[0].content.content} lP={testimonials[0].name} lC={`Client since ${testimonials[0].date}`}
        rQ={testimonials[1].content.content} rP={testimonials[1].name} rC={`Client since ${testimonials[1].date}`} />
        testimonials.shift();
        testimonials.shift();
    }

    return (<Layout title="Testimonials" des="Marcus Moran has been selling insurance for a while, and many people are pleased with the results.">
        <div>
            <div className="bg-blue-900 pb-5 md:pb-10 mb-10 relative">
                <Img fluid={banner.sizes} alt="Banner"/>
                <h1 className="absolute text-2xl md:text-4xl lg:text-5xl text-blue-900 italic words-xs lg:words-md">Testimonials</h1>
            </div>
            {sbsT}
            {testimonials.map((testimonial) => {
                return <Single Q={testimonial.content.content} P={testimonial.name} C={`Client since ${testimonial.date}`} key={testimonial.name} />
            })}
            
            <Contact msg="Join these people! I'd love the opportunity to get you covered!"/>
        </div>
    </Layout>)
}

export const query = graphql`
    query{
        allContentfulTestimonialPage {
            nodes {
              banner {
                sizes(maxWidth: 1700, quality: 100) {
                    ...GatsbyContentfulSizes
                }
              }
              testimonialList {
                name
                date
                content {
                  content
                }
              }
            }
          }
    }
`